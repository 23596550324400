import React,{ useContext} from 'react';
import Marquee from "react-fast-marquee";
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import { ThemeContext } from '../../contexts/ThemeContext';
import { projectsData } from '../../data/projectsData'
import { HiArrowRight } from "react-icons/hi";

import './Projects.css'
import SingleProject from './SingleProject/SingleProject';

function Projects() {

    const { theme } = useContext(ThemeContext);

    
    const useStyles = makeStyles(() => ({
        viewAllBtn : {
            color: theme.tertiary80, 
            backgroundColor: theme.secondary70,
            border: `2px solid ${theme.fourth}`,
            transition: 'color 0.2s',
            "&:hover": {
                color: theme.secondary, 
                backgroundColor: theme.tertiary70,
            },
            overflow: 'hidden',
        },
        viewArr : {
            color: theme.tertiary80, 
            backgroundColor: theme.secondary70,
            width: '40px',
            height: '40px',
            padding: '0.5rem',
            fontSize: '1.05rem',
            borderRadius: '50%',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            "&:hover": {
                color: theme.secondary, 
                backgroundColor: theme.tertiary70,
            }
        },
    }));

    const classes = useStyles();

    return (
        <>
            {projectsData.length > 0 && (
                <div className="projects" id="projects" style={{backgroundColor: theme.secondary}}>
                    <div className="projects--header">
                        <h1 style={{color: theme.primary}}>Projects</h1>
                    </div>
                    <div className="projects--body">
                        <div className="projects--bodyContainer">
                            <Marquee 
                                gradient={false} 
                                speed={50} 
                                pauseOnHover={true}
                                pauseOnClick={true} 
                                delay={0}
                                play={true} 
                                direction="left"
                            >
                                {projectsData.map(project => (
                                    <SingleProject
                                        theme={theme}
                                        key={project.id}
                                        id={project.id}
                                        name={project.projectName}
                                        desc={project.projectDesc}
                                        tags={project.tags}
                                        code={project.code}
                                        demo={project.demo}
                                        image={project.image}
                                    />
                                ))}
                            </Marquee>
                        </div> 

                        <div className="projects--viewAll">
                            <Link to="/projects">
                                <button className={classes.viewAllBtn}>
                                    View All
                                    <HiArrowRight className={classes.viewArr} />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default Projects
