export const educationData = [
    {
        id: 1,
        institution: 'Binghamton University',
        course: 'Bachelor of Science (MIS, Finance)',
        startYear: '2010',
        endYear: '2014'
    },
    {
        id: 2,
        institution: 'Galvanize',
        course: 'Data Science Bootcamp',
        startYear: '2019',
        endYear: '2019'
    },
    {
        id: 3,
        institution: 'IBM',
        course: 'Data Science Apprenticeship',
        startYear: '2020',
        endYear: '2020'
    },
]