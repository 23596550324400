

export const interestsImage = (interest) => {
    const interestID = interest.toLowerCase();
    switch (interestID) {
        case 'philosophize this!':
            return 'https://i.scdn.co/image/ab6765630000ba8a16116dc84b5da8d9905a35ec';
        case 'hardcore history':
            return 'https://m.media-amazon.com/images/I/518646oSbiL.jpg';
        case 'tom misch - beat tape 2':
            return 'https://i1.sndcdn.com/artworks-000127759401-1nrihe-t500x500.jpg';
        case 'chance the rapper - coloring book':
            return 'https://upload.wikimedia.org/wikipedia/en/c/c4/Chance_the_Rapper_-_Coloring_Book.png';
        case 'the beatles - abbey road':
            return 'https://upload.wikimedia.org/wikipedia/en/4/42/Beatles_-_Abbey_Road.jpg';
        case 'statquest':
            return 'https://pbs.twimg.com/profile_images/1410315179854811145/aOs7Ec0M_400x400.jpg';
        case 'lex fridman podcast':
            return 'https://i.scdn.co/image/ab6765630000ba8a563ebb538d297875b10114b7';
        case 'thinking basketball':
            return 'https://i1.sndcdn.com/avatars-000569184126-gxlhof-t500x500.jpg';
        case 'nba':
            return 'https://www.samford.edu/sports-analytics/fans/2023/images/NBA-Logo.webp';
        case 'new york knicks':
            return 'https://upload.wikimedia.org/wikipedia/en/thumb/2/25/New_York_Knicks_logo.svg/1200px-New_York_Knicks_logo.svg.png';
        case 'nfl':
            return 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a2/National_Football_League_logo.svg/1200px-National_Football_League_logo.svg.png';
        case 'new york jets':
            return 'https://yt3.googleusercontent.com/7TCQSq2jWIU17VdVc3A09tiI2SAWa_PqE_HhkpU-QVIBLf5LZX-HLL-wkE_OpFPxmxDQpQIg3A=s900-c-k-c0x00ffffff-no-rj';
        case 'new york yankees':
            return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/New_York_Yankees_Primary_Logo.svg/1200px-New_York_Yankees_Primary_Logo.svg.png';
        case 'basketball':
            return 'https://target.scene7.com/is/image/Target/GUEST_20affc7e-e0d7-4eb6-a6f3-68d13520f8be?wid=488&hei=488&fmt=pjpeg';
        case 'walking':
            return 'https://www.freevector.com/uploads/vector/preview/30715/Hiking_On_Summer.jpg';
        case 'red hearse - red hearse':
            return 'https://consequence.net/wp-content/uploads/2019/08/RED-HEARSE_Single-Artwork.jpg';
        case 'greta van fleet - starcatcher':
            return 'https://upload.wikimedia.org/wikipedia/en/6/6b/Starcatcher_Greta_Van_Fleet.jpg';
        case 'twenty one pilots - vessel':
            return 'https://upload.wikimedia.org/wikipedia/en/2/20/Vessel_by_Twenty_One_Pilots.jpg';
        case 'frank ocean - blonde':
            return 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a0/Blonde_-_Frank_Ocean.jpeg/220px-Blonde_-_Frank_Ocean.jpeg';
        case 'maggie rogers - heard it in a past life':
            return 'https://upload.wikimedia.org/wikipedia/en/f/f3/Maggie_Rogers_-_Heard_It_in_a_Past_Life.png';
        case 'bon iver - 22, a million':
            return 'https://media.pitchfork.com/photos/5935a1014fc0406ca110ccc9/master/pass/fd8402f9.jpg';
        case 'the history of rome':
            return 'https://upload.wikimedia.org/wikipedia/en/c/cf/The_History_of_Rome.png';
        case 'revolutions':
            return 'https://i.scdn.co/image/ab6765630000ba8ae4e166660208f9e100cc69cb';
        case 'the voyages of marco polo':
            return 'https://m.media-amazon.com/images/I/91aWHct+-VL._AC_UF894,1000_QL80_.jpg';
        case 'settlers of catan':
            return 'https://upload.wikimedia.org/wikipedia/en/a/a3/Catan-2015-boxart.jpg';
        case 'crash course':
            return 'https://upload.wikimedia.org/wikipedia/en/e/e9/Crash_Course_logo.png';
        case 'pbs eons':
            return 'https://image.pbs.org/contentchannels/SZFXMsf-show-poster2x3-KUUXYdL.jpg';
        case 'pbs space time':
            return 'https://pbs.twimg.com/profile_images/565257646928691200/2KzLAp-5_400x400.png';
        case 'towards data science':
            return 'https://cdn-images-1.medium.com/v2/resize:fit:1200/1*CJe3891yB1A1mzMdqemkdg.jpeg';
        case '3blue1brown':
            return 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/3B1B_Logo.svg/1200px-3B1B_Logo.svg.png';
        case 'world science festival':
            return 'https://upload.wikimedia.org/wikipedia/commons/8/81/WSF_logo_300.jpg';
        case 'parks and rec':
            return 'https://m.media-amazon.com/images/M/MV5BYWNkOTg0OTMtZTcyNy00MWU1LWJhZDQtYjQzMjU1NjBhYzI2XkEyXkFqcGdeQXVyOTE4NzcwNzI@._V1_.jpg';
        case "the good place":
            return 'https://upload.wikimedia.org/wikipedia/en/8/84/The_Good_Place_season_1.jpg';
        case "my hero academia":
            return 'https://res.cloudinary.com/jerrick/image/upload/v1507001187/m3hpmuzz2flbdhsegq0c.png';
        case "it's always sunny in philadelphia":
            return 'https://m.media-amazon.com/images/I/91zdZAy8FOL._AC_UF894,1000_QL80_.jpg';
        case "mistborn":
            return "https://m.media-amazon.com/images/I/81NGmugxgSL._AC_UF1000,1000_QL80_.jpg";
        case "guns, germs, and steel":
            return "https://m.media-amazon.com/images/I/61V8g4GgqdL._AC_UF1000,1000_QL80_.jpg";
        case "the meditations":
            return "https://m.media-amazon.com/images/I/61lgOKkb3QL._AC_UF1000,1000_QL80_.jpg";
        case "fear and trembling":
            return "https://cdn.penguin.co.uk/dam-assets/books/9780141395883/9780141395883-jacket-large.jpg";
        case "avatar: the last airbender":
            return "https://m.media-amazon.com/images/I/61Wps+FMG-L._AC_UF1000,1000_QL80_.jpg";
        case "the legend of korra":
            return "https://upload.wikimedia.org/wikipedia/en/3/3c/The_Legend_of_Korra_%28Platinum_Games%29_video_game_cover.jpg";
        case "harry potter":
            return "https://images.booksense.com/images/595/139/9780439139595.jpg";
        case "severance":
            return "https://m.media-amazon.com/images/M/MV5BMjkwZjcwMGQtNDAzOC00YjJiLThiYTgtNWU3ZjRiZmY2YzEzXkEyXkFqcGdeQXVyMTMzNDExODE5._V1_.jpg";
        default:
            break;
    }
}

