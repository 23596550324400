import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from 'react-reveal/Fade';

import { ThemeContext } from '../../contexts/ThemeContext';

import expImgWhite from '../../assets/svg/experience/expImgWhite.svg'
import expImgBlack from '../../assets/svg/experience/expImgBlack.svg'

import './Experience.css'

function ExperienceCard({id, company, jobtitle, startYear, endYear, jobHighlights, jobDetails, isExpanded, onCardClick}) {

    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles((t) => ({
        experienceCard : {
            backgroundColor: "transparent",
            border: `2px solid ${theme.primary30}`,
            "&:hover": {
                backgroundColor:theme.primary30,
            },
        },
    }));

    const classes = useStyles();

    // Function to render expanded content, adjust as needed
    const renderExpandedContent = () => (
        <div className="experience-details-expanded">
          <h4 style={{ color: theme.tertiary }}>Highlights</h4>
          <ul className="highlight-list">
            {jobHighlights.map((highlight, index) => (
              <li key={index} style={{ color: theme.tertiary80 }} >{highlight}</li>
            ))}
          </ul>
          <h4 style={{ color: theme.tertiary }}>Details</h4>
          <ul className="details-list">
            {jobDetails.map((detail, index) => (
              <li key={index} style={{ color: theme.tertiary80 }}>{detail}</li>
            ))}
          </ul>
        </div>
    );

    console.log('Rendering ExperienceCard');

    // Use the isExpanded prop to adjust the card's class or style
    // Add onClick handler to the card's main div
    return (
        <Fade>
          <div onClick={() => onCardClick(id)} className={`experience-card ${isExpanded ? 'expanded-card' : ''} ${classes.experienceCard}`}>
            <div className="expcard-img" style={{ backgroundColor: theme.primary }}>
              <img src={theme.type === 'light' ? expImgWhite : expImgBlack} alt="" />
            </div>
            <div className="experience-details">
              <h6 style={{ color: theme.primary }}>{startYear}-{endYear}</h6>
              <h4 style={{ color: theme.tertiary }}>{jobtitle}</h4>
              <h5 style={{ color: theme.tertiary80 }}>{company}</h5>
            </div>
            {isExpanded && renderExpandedContent()}
          </div>
        </Fade>
      );
}

export default ExperienceCard
