import React, { useContext, useEffect, useState } from 'react';
import './Interests.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { interestsData } from '../../data/interestsData';
import { interestsImage } from '../../utils/interestsImage';
import orange_dice_img from '../../assets/svg/interests/orange_dice.svg';
import dice_img from '../../assets/svg/interests/dice.svg';

function Interests() {
    const { theme } = useContext(ThemeContext);
    const [randomInterests, setRandomInterests] = useState([]);
    const [diceState, setDiceState] = useState({ shaking: false,}); // Manage both shaking and fading states
    const [cardAnimations, setCardAnimations] = useState([]);
    

    useEffect(() => {
        setRandomInterests(getRandomInterests());
    }, []);

    const handleDiceClick = () => {

        // Start with shaking the dice
        setDiceState({ ...diceState, shaking: true });
    
        // Wait for the shake animation to complete
        setTimeout(() => {
            // Reset shaking and initiate fade-out for each card
            // const updatedCardAnimations = randomInterests.map((_, index) => 
            //     setTimeout(() => 'fadeOutToBottom', index * 100) // Placeholder operation
            // );
            setCardAnimations(randomInterests.map(() => 'fadeOutToBottom'));
    
            // Reset animations to fade out (though this step might need adjustments)
            // setCardAnimations(updatedCardAnimations);
    
            // After the last card fades out, update the interests and prepare for fade-in
            setTimeout(() => {
                const newInterests = getRandomInterests(); // Update interests
                setRandomInterests(newInterests);
    
                // Reset shaking state and prepare new cards to fade in from the top
                setDiceState({ ...diceState, shaking: false });
    
                // Apply 'fadeInFromTop' animation to each new interest
                setCardAnimations(newInterests.map(() => 'fadeInFromTop'));
            }, randomInterests.length * 100 + 500); // Adjust timing based on animation duration
            // Simulate fade-in by waiting for the "update" process to finish
            
        }, 100); // This should match the duration of the shake animation
    };
    const getRandomInterests = () => {
        return interestsData.slice().sort(() => Math.random() - 0.5).slice(0, 3);
    };

    return (
        <div className="interestsContainer" style={{ backgroundColor: theme.secondary }}>
            <div className="interests" style={{ backgroundColor: theme.secondary }}>
                <div className="interestsHeader" onClick={handleDiceClick}>
                    <h2 style={{ color: theme.primary }}>Personal Interests</h2>
                    <div className="imagesContainer">
                        <img src={orange_dice_img} alt="dice" id="orangeDice" className={diceState.shaking ? 'shake' : ''} />
                        <img src={dice_img} alt="dice" id="bwDice" />

                    </div>
                </div>
                <div className="interests--body">
                    {randomInterests.map((interest, index) => (
                        <div
                            className={`interests--box ${cardAnimations[index]}`}
                            key={index}
                            style={{
                                backgroundColor: theme.secondary,
                                boxShadow: `0px 0px 30px ${theme.primary30}`,
                                animationDelay: `${index * 0.2}s`, // Apply staggered delay for fade-in
                            }}
                        >   
                            <div className="image-wrapper">
                                <img src={interestsImage(interest.name)} alt={interest.name} />
                            </div>
                            <div className="interest--details">
                                <h3 style={{ color: theme.tertiary }}>{interest.name}</h3>
                                <ul className="interest-list">
                                    <li style={{ color: theme.primary }}>{interest.medium}</li>
                                    <li style={{ color: theme.primary }}>{interest.category}</li>
                                    <li style={{ color: theme.primary }}>{interest.subCategory}</li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Interests;
