export const experienceData = [
    {
        id: 1,
        company: 'American Express',
        jobtitle: 'ML Engineer',
        startYear: '2021',
        endYear: 'Present',
        highlights: ["Python", "FastAPI", "Docker", "Natural Language Understanding", "Computer Vision", "SQL", "Shell Scripting", "Kubernetes", "Agile",  "Mentorship", "Great Teammate"],
        details: [
            "Built chatbot framework integrated with Slack that allows product teams to easily deploy customizable chatbots for their unique use cases",
            "Deployed and maintain three computer vision services for understanding and classifying documents",
            "Evaluate, identify, and remediate vulnerabilities present across 10 services to maintain a secure, reliable, and highly effective technical environment for 4000 end users",
            // "Engineer shell scripts to streamline local environment setup and improve development efficiency for other team members"
        ]
    },
    {
        id: 2,
        company: 'IBM',
        jobtitle: 'Data Scientist',
        startYear: '2020',
        endYear: '2021',
        highlights: ["Python", "Data Mining", "Sentiment Analysis", "Cloud Computing", "SpEL", "Client Facing", "Data Visualization", "Loved by Direct Supervisor"],
        details: [
            "Spearheaded the design and implementation of cloud-based NLU cognitive assistants for 3 regional banking clients, utilizing IBM Watson and Google Dialow",
            "Designed NLP Sentiment analysis and data visualization pipeline leveraging Twitter’s API to deliver information about customer perceptions of a global client’s COVID-19 response",
            "Performed technical writing and produced an internal monthly newsletter concerning AI solutions across the banking and insurance industries"
        ]
    },
    {
        id: 3,
        company: 'Fulfyll',
        jobtitle: 'Entrepreneur',
        startYear: '2017',
        endYear: '2019',
        highlights: ["Business Startegy", "Project Management", "People Management", "React", "Self Starter", "Wordpress", "Sales", "A/B Testing"],
        details: [
            "Oversaw a 4-person team throughout the creation, testing, and deployment of a React Native and Ruby on Rails Fulfyll mobile application",
            "Developed and maintained a dynamic strategy document, serving as a comprehensive repository for key insights and analysis critical to organizational success",
            "Conducted three rounds of A/B testing, extracting actionable insights to drive informed decision-making and garner necessary insights to inform product launch"
        ]
    },
    {
        id: 4,
        company: 'BAE Systems',
        jobtitle: 'Financial Analyst',
        startYear: '2014',
        endYear: '2017',
        highlights: ["Public Speaking", "Data Analysis", "Data Visualization", "Excel", "VBA", "Attention to Detail", "Cross Functional Teams"],
        details: [
            "Received Finance Achievement Award for development of proprietary Sector Rates Model which used VBA to help leadership of $4b sector understand the effects of various financial and personnel decisions",
            "Presented audit findings spanning domestic and international BAE Systems facilities to senior leadership leading to long term material changes in operating procedures for various locations",
            "Lead financial analyst on multiple cross-functional teams with combined contract values exceeding $50m",
            // "Created Excel workbook with VBA which allowed for more timely and accurate financial projections"
        ]
    },
]