import resume from '../assets/pdf/resume.pdf'
import discoPic from '../assets/png/discoPic.png'
import andrewWedding from '../assets/png/andrewWedding.png'
import beach from '../assets/png/beach.png'
import chilling from '../assets/png/chilling.png'
import michael_wedding from '../assets/png/michael_wedding.jpg'
import nba_playoffs from '../assets/png/nba_playoffs.jpeg'
import skydiving from '../assets/png/skydiving.jpg'
import swiss_fountain from '../assets/png/swiss_fountain.jpg'
import grandpa from '../assets/png/grandpa.jpg'
import golf from '../assets/png/golf.jpg'
import christmas from '../assets/png/christmas.jpg'
import musical from '../assets/png/musical.jpg'
import hiking from '../assets/png/hiking.png'



export const headerData = {
    name: 'Jacob Press',
    title: "Human",
    desciption1:"Welcome to my website!",
    desciption2:"My name is Jacob Press. After a decade of career twists and turns I find myself working as a machine learning engineer. I think my diverse set of experiences and interests gives me a unique perspective that makes me valuable member of any team I find myself a part of. I hope this website helps you get a sense of who I am both professionally and personally.",
    image: [discoPic, skydiving, andrewWedding, swiss_fountain, beach, hiking, musical, nba_playoffs, michael_wedding, grandpa, golf, christmas, chilling],
    resumePdf: resume
}
