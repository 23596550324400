import React, { useContext, useState, useEffect, useRef } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import './Experience.css';
import { experienceData } from '../../data/experienceData';
import ExperienceCard from './ExperienceCard';

const Experience = () => {
    const [expandedCard, setExpandedCard] = useState(null);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const { theme } = useContext(ThemeContext);

    // Add state to manage whether the screen width is below 820px
    let cardSectionHeight = useRef(`${experienceData.length * 140}px`);

    useEffect(() => {
        
        const updateScreenSize = () => {
            if (window.innerWidth <= 680) {
                // Correctly update the .current property of the ref
                cardSectionHeight.current = `${experienceData.length * 50}px`;
            } else {
                // Reset to default height if the condition doesn't match
                cardSectionHeight.current = `${experienceData.length * 140}px`;
            }
        };

        // Check screen size on mount
        updateScreenSize();

        // Add event listener for window resize
        window.addEventListener('resize', updateScreenSize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', updateScreenSize);
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount



    const handleCardClick = (id) => {
        if (expandedCard === id) {
            setExpandedCard(null);
            setIsFadingOut(false); // Reset fading only if the expanded card is clicked again
        } else {
            if (expandedCard === null) {
                setIsFadingOut(true); // Trigger fade-out only when no card is expanded
            }
            setTimeout(() => {
                setExpandedCard(id);
                // Keep isFadingOut true to maintain fade-out on other cards
            }, 500); // Delay for switching order, allowing fade-out effect
        }
    };

    const getOrderedExperienceData = () => {
        if (expandedCard === null) return experienceData; // Return original order if no card is expanded
        return [
            experienceData.find(exp => exp.id === expandedCard), // Put expanded card at the beginning
            ...experienceData.filter(exp => exp.id !== expandedCard), // Then the rest of the cards
        ];
    };

    const orderedExperienceData = getOrderedExperienceData();

    return (
        <div className="experience" id="experience" style={{ backgroundColor: theme.secondary }}>
            <div className="experience-body">
                <div className="experience-image">
                    <img src={theme.expimg} alt="" />
                </div>
                <div className="experience-description">
                    <h1 style={{ color: theme.primary }}>Experience</h1>
                    <div className='card-section' style={{ height: cardSectionHeight.current }}>
                        {orderedExperienceData.map(exp => (
                            <div
                                key={exp.id}
                                className={`${isFadingOut && exp.id !== expandedCard ? 'experience-card-wrapper hidden-card' : expandedCard === exp.id ? 'experience-card-wrapper expanded-card' : 'experience-card-wrapper'}`}
                                onClick={() => handleCardClick(exp.id)}
                            >
                                <ExperienceCard
                                    key={exp.id}
                                    id={exp.id}
                                    jobtitle={exp.jobtitle}
                                    company={exp.company}
                                    startYear={exp.startYear}
                                    endYear={exp.endYear}
                                    jobHighlights={exp.highlights}
                                    jobDetails={exp.details}
                                    isExpanded={expandedCard === exp.id}
                                    onCardClick={() => handleCardClick(exp.id)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;