import React, { useContext, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { makeStyles } from '@material-ui/core/styles';

import './Landing.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { headerData } from '../../data/headerData';
import { socialsData } from '../../data/socialsData';

import {
    FaLinkedin,
    FaGithub,
} from 'react-icons/fa';

function Landing() {
    const { theme, drawerOpen } = useContext(ThemeContext);

    const useStyles = makeStyles((t) => ({
        resumeBtn: {
            color: theme.primary,
            borderRadius: '30px',
            textTransform: 'inherit',
            textDecoration: 'none',
            width: '150px',
            fontSize: '1rem',
            fontWeight: '500',
            height: '50px',
            fontFamily: 'var(--primaryFont)',
            border: `3px solid ${theme.primary}`,
            transition: '100ms ease-out',
            '&:hover': {
                backgroundColor: theme.tertiary,
                color: theme.secondary,
                border: `3px solid ${theme.tertiary}`,
            },
            [t.breakpoints.down('sm')]: {
                width: '180px',
            },
        },
        contactBtn: {
            backgroundColor: theme.primary,
            color: theme.secondary,
            borderRadius: '30px',
            textTransform: 'inherit',
            textDecoration: 'none',
            width: '150px',
            height: '50px',
            fontSize: '1rem',
            fontWeight: '500',
            fontFamily: 'var(--primaryFont)',
            border: `3px solid ${theme.primary}`,
            transition: '100ms ease-out',
            '&:hover': {
                backgroundColor: theme.secondary,
                color: theme.tertiary,
                border: `3px solid ${theme.tertiary}`,
            },
            [t.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    }));

    const classes = useStyles();

    // Image effect
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [shutterClosed, setShutterClosed] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setShutterClosed(true);

            setTimeout(() => {
                setCurrentImageIndex(currentIndex => (currentIndex + 1) % headerData.image.length);

                setTimeout(() => {
                    setShutterClosed(false);
                }, 200);
            }, 250);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    // Title Effect
    const targetWord = headerData.title;
    const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}|:<>?[];',./-=`~";

    // Constants
    const rotationTime = 3000; // Total time for rotations in milliseconds
    const lagTime = 500; // Time lag between each character stopping in milliseconds


    const [characters, setCharacters] = useState(targetWord.split('').map(() => ''));

    useEffect(() => {
        let intervals = []; // Define intervals array to store interval IDs
        targetWord.split('').forEach((finalChar, index) => {
            let interval = setInterval(() => {
                setCharacters(chars =>
                    chars.map((char, i) => (i === index ? randomChars[Math.floor(Math.random() * randomChars.length)] : char))
                );
                // Check if it's time to stop this character's rotation
                if (Date.now() - startTime >= rotationTime + index * lagTime) {
                    clearInterval(interval);
                    setCharacters(chars => chars.map((char, i) => (i === index ? finalChar : char)));
                }
            }, 100 + index * 50); // Different intervals for each character
            intervals.push(interval); // Store the interval ID
        });

        const startTime = Date.now(); // Record start time

        return () => intervals.forEach(clearInterval); // Cleanup on unmount
    }, [targetWord]);


    return (
        <div className='landing'>
            <div className='landing--container'>
                <div
                    className='landing--container-left'
                    style={{ backgroundColor: theme.primary }}
                >
                    <div className='lcl--content'>
                        {socialsData.linkedIn && (
                            <a
                                href={socialsData.linkedIn}
                                target='https://www.linkedin.com/in/jacob-press-867b3541/'
                                rel='noreferrer'
                            >
                                <FaLinkedin
                                    className='landing--social'
                                    style={{ color: theme.fourth80 }}
                                    aria-label='LinkedIn'
                                />
                            </a>
                        )}
                        {socialsData.github && (
                            <a
                                href={socialsData.github}
                                target='https://github.com/jacobfulfyll'
                                rel='noreferrer'
                            >
                                <FaGithub
                                    className='landing--social'
                                    style={{ color: theme.fourth80 }}
                                    aria-label='GitHub'
                                />
                            </a>
                        )}
                    </div>
                </div>
                <div className='landing--img-container' style={{ opacity: `${drawerOpen ? '0' : '1'}` }}>
                    <div className='landing--img-wrapper'>
                        <img
                            src={headerData.image[currentImageIndex]}
                            alt=''
                            className={`landing--img`}
                            style={{
                                borderColor: theme.secondary,
                            }}
                        />
                        <div className={`shutter-left ${shutterClosed ? 'closeShutter' : 'openShutter'}`} style={{ '--shutterColor': theme.primary }}></div>
                        <div className={`shutter-right ${shutterClosed ? 'closeShutter' : 'openShutter'}`} style={{ '--shutterColor': theme.secondary }}></div>
                    </div>
                </div>
                <div
                    className='landing--container-right'
                    style={{ backgroundColor: theme.secondary }}
                >
                    <div
                        className='lcr--content'
                        style={{ color: theme.tertiary }}
                    >
                        <div className='title-container'>
                            {characters.map((char, i) => (
                                <h6 key={i} className="character">
                                {char}
                                </h6>
                            ))}
                        </div>
                        <h1>{headerData.name}</h1>
                        <p>{headerData.desciption1}</p>
                        <p>{headerData.desciption2}</p>

                        <div className='lcr-buttonContainer'>
                            {headerData.resumePdf && (
                                <a
                                    href={headerData.resumePdf}
                                    download='resume'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <Button className={classes.resumeBtn}>
                                        Download CV
                                    </Button>
                                </a>
                            )}
                            <NavLink
                                to='/#contacts'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <Button className={classes.contactBtn}>
                                    Contact
                                </Button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;
