import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
// import seven from '../assets/svg/projects/seven.svg'
// import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'NBA Wins Contributed',
        projectDesc: 'Created NBA stat that values players based onthe context of their teammates. Used python and sql to get, store, compute, and display a variety of player and team info related to Wins Contributed including creating supervised and unsupervised learning models to predict similar players and team wins for the upcoming season.',
        tags: ['Supervised ML', 'Unsupervised ML', 'SQL', 'Python', 'Matplotlib', 'Scikit Learn', 'Pandas', 'Numpy'],
        code: 'https://github.com/jacobfulfyll/value_contributed',
        demo: 'https://github.com/jacobfulfyll/value_contributed',
        image: one
    },
    {
        id: 2,
        projectName: 'Personal Website',
        projectDesc: 'Built this website! This iteration uses React for front end but previous iterations used Flask as the server and a python front end',
        tags: ['React', 'HTML', 'CSS', 'Javascript', 'Material Ui', 'Flask', 'Heroku'],
        code: 'https://github.com/jacobfulfyll/personal_website',
        demo: 'https://github.com/jacobfulfyll/personal_website',
        image: two
    },
    {
        id: 3,
        projectName: 'Daily Fantasy Bot',
        projectDesc: 'A bot that scrapes data and predicts an optimal daily fantasy lineup with the goal of winning 1 on 1 contests greater than 57% of the time. Used selenium to scrape data, python to store and analyze data, and machine learning to predict the best lineup.',
        tags: ['Selenium', 'Python', 'Time Series Analysis', 'MongoDB', 'XGBoost', 'Pandas', 'Numpy'],
        code: 'https://github.com/jacobfulfyll/dfs_bot',
        demo: 'https://github.com/jacobfulfyll/dfs_bot',
        image: three
    },
    {
        id: 4,
        projectName: 'Presidential Profiles',
        projectDesc: 'Explored the history of the United States through the lens of presidential speeches. Uses many AWS nodes to scrape data quickly; NLU techniques and data visualization to display insights from speeches; and Unsuperivsed ML to create similarity clusters for Presidents',
        tags: ['AWS', 'Matplotlib', 'Python', 'Seaborn', 'SQL', 'NLTK', 'Tensorflow', 'Spacy', 'Plotly', 'SciKit Learn', 'BeautifulSoup'],
        code: 'https://github.com/jacobfulfyll/presidential_profiles/tree/master/american_values',
        demo: 'https://github.com/jacobfulfyll/presidential_profiles/tree/master/american_values',
        image: four
    },
    {
        id: 5,
        projectName: 'Fraud Detection',
        projectDesc: 'A group project from the data science bootcamp where we built an application that leveraged a self-made fraud model to predict the likelihood of fraud in a transaction. We used Flask to create the front end and back end and used supervised machine learning to create the model. We also used MongoDB to store the data.',
        tags: ['Python', 'Flask', 'Bootstrap', 'HTML', 'CSS', 'MongoDB', 'Scikit Lean', 'Supervised ML'],
        code: 'https://github.com/justinho8161/dsi-fraud-detection-case-study/tree/master',
        demo: 'https://github.com/justinho8161/dsi-fraud-detection-case-study/tree/master',
        image: five
    },
    {
        id: 6,
        projectName: 'Instagram Bot',
        projectDesc: 'A bot that scraped data from Instagram and used computer vision, nlu, and statistics to find similarities in images and captions that were correlated with high engagement.',
        tags: ['Selenium', 'Python', 'MongoDB', 'Pandas', 'Numpy', 'Matplotlib', 'Scikit Learn', 'NLU', 'Machine Learning'],
        code: 'https://github.com/jacobfulfyll/anxious_and_abroad',
        demo: 'https://github.com/jacobfulfyll/anxious_and_abroad',
        image: six
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/