export const interestsData = [
    {
        name: 'Philosophize This!',
        medium: 'Podcast',
        category: 'Learning',
        subCategory: 'Philosophy',
        link: 'https://www.philosophizethis.org/',
    },
    {
        name: 'Hardcore History',
        medium: 'Podcast',
        category: 'Learning',
        subCategory: 'History',
        link: 'https://www.dancarlin.com/hardcore-history-series/',
    },
    {
        name: 'Tom Misch - Beat Tape 2',
        medium: 'Music',
        category: 'Jazz-Funk',
        subCategory: 'Hip-Hop',
        link: 'https://www.tommisch.com/',
    },
    {
        name: 'Chance the Rapper - Coloring Book',
        medium: 'Music',
        category: 'Hip-Hop',
        subCategory: 'Gospel',
        link: 'https://www.chanceraps.com/',
    },
    {
        name: 'The Beatles - Abbey Road',
        medium: 'Music',
        category: 'Rock',
        subCategory: 'Classic Rock',
        link: 'https://www.thebeatles.com/',
    },
    {
        name: 'StatQuest',
        medium: 'YouTube',
        category: 'Learning',
        subCategory: 'Machine Learning',
        link: 'https://statquest.org/',
    },
    {
        name: 'Lex Fridman Podcast',
        medium: 'Podcast',
        category: 'Learning',
        subCategory: 'Artificial Intelligence',
        link: 'https://lexfridman.com/podcast/',
    },
    {
        name: 'Thinking Basketball',
        medium: 'Podcast',
        category: 'Sports',
        subCategory: 'Basketball',
        link: 'https://thinkingbasketball.net/',
    },
    {
        name: 'NBA',
        medium: 'TV',
        category: 'Sports',
        subCategory: 'Basketball',
        link: 'https://nba.com/',
    },
    {
        name: 'New York Knicks',
        medium: 'TV',
        category: 'Sports',
        subCategory: 'Basketball',
        link: 'https://nba.com/knicks/',
    },
    {
        name: 'NFL',
        medium: 'TV',
        category: 'Sports',
        subCategory: 'Football',
        link: 'https://nfl.com/',
    },
    {
        name: 'New York Jets',
        medium: 'TV',
        category: 'Sports',
        subCategory: 'Football',
        link: 'https://www.newyorkjets.com/',
    },
    {
        name: 'New York Yankees',
        medium: 'TV',
        category: 'Sports',
        subCategory: 'Baseball',
        link: 'https://www.mlb.com/yankees',
    },
    {
        name: 'Basketball',
        medium: 'Activity',
        category: 'Fitness',
        subCategory: 'Sports',
    },
    {
        name: 'Walking',
        medium: 'Activity',
        category: 'Fitness',
        subCategory: 'Mental Clarity',
    },
    {
        name: 'Red Hearse - Red Hearse',
        medium: 'Music',
        category: 'Synth-Pop',
        subCategory: 'R&B',
        link: 'https://www.redhearse.com/',
    },
    {
        name: 'Greta Van Fleet - Starcatcher',
        medium: 'Music',
        category: 'Rock',
        subCategory: 'Blues Rock',
        link: 'https://gretavanfleet.com/',
    },
    {
        name: 'Twenty One Pilots - Vessel',
        medium: 'Music',
        category: 'Indie Pop',
        subCategory: 'Alternative Rock',
        link: 'https://www.twentyonepilots.com/',
    },
    {
        name: 'Frank Ocean - Blonde',
        medium: 'Music',
        category: 'Aletnative R&B',
        subCategory: 'Neo-Soul',
        link: 'https://blonded.co/',
    },
    {
        name: 'Maggie Rogers - Heard It In a Past Life',
        medium: 'Music',
        category: 'Electropop',
        subCategory: 'Folk-pop',
        link: 'https://blonded.co/',
    },
    {
        name: 'Bon Iver - 22, a Million',
        medium: 'Music',
        category: 'Experimental Folk',
        subCategory: 'Folktronica',
        link: 'https://blonded.co/',
    },
    {
        name: 'The History of Rome',
        medium: 'Podcast',
        category: 'Learning',
        subCategory: 'History',
        link: 'https://thehistoryofrome.typepad.com/',
    },
    {
        name: 'Revolutions',
        medium: 'Podcast',
        category: 'Learning',
        subCategory: 'History',
        link: 'https://thehistoryofrome.typepad.com/revolutions_podcast/',
    },
    {
        name: 'The Voyages of Marco Polo',
        medium: 'Game',
        category: 'Board Game',
        subCategory: 'Strategy',
        link: 'https://boardgamegeek.com/boardgame/171623/voyages-marco-polo',
    },
    {
        name: 'Settlers of Catan',
        medium: 'Game',
        category: 'Board Game',
        subCategory: 'Strategy',
        link: 'https://www.catan.com/',
    },
    {
        name: 'Crash Course',
        medium: 'Youtube',
        category: 'Learning',
        subCategory: 'Various',
        link: 'https://www.youtube.com/@crashcourse',
    },
    {
        name: 'PBS Eons',
        medium: 'Youtube',
        category: 'Learning',
        subCategory: 'Natural History',
        link: 'https://www.youtube.com/@eons',
    },
    {
        name: 'PBS Space Time',
        medium: 'Youtube',
        category: 'Learning',
        subCategory: 'Physics',
        link: 'https://www.youtube.com/@eons',
    },
    {
        name: 'Towards Data Science',
        medium: 'Blog',
        category: 'Learning',
        subCategory: 'Data Science',
        link: 'https://towardsdatascience.com/',
    },
    {
        name: '3Blue1Brown',
        medium: 'Youtube',
        category: 'Learning',
        subCategory: 'Mathematics',
        link: 'https://www.3blue1brown.com/',
    },
    {
        name: 'World Science Festival',
        medium: 'Youtube',
        category: 'Learning',
        subCategory: 'Science',
        link: 'https://www.worldsciencefestival.com/',
    },
    {
        name: 'Parks and Rec',
        medium: 'Television',
        category: 'Entertainment',
        subCategory: 'Comedy',
        link: 'https://en.wikipedia.org/wiki/Parks_and_Recreation',
    },
    {
        name: 'The Good Place',
        medium: 'Television',
        category: 'Entertainment',
        subCategory: 'Comedy',
        link: 'https://en.wikipedia.org/wiki/The_Good_Place',
    },
    {
        name: 'My Hero Academia',
        medium: 'Television',
        category: 'Entertainment',
        subCategory: 'Anime',
        link: 'https://en.wikipedia.org/wiki/My_Hero_Academia',
    },
    {
        name: "It's Always Sunny in Philadelphia",
        medium: 'Television',
        category: 'Entertainment',
        subCategory: 'Comedy',
        link: 'https://en.wikipedia.org/wiki/It%27s_Always_Sunny_in_Philadelphia',
    },
    {
        name: "Severance",
        medium: 'Television',
        category: 'Entertainment',
        subCategory: 'Thriller',
        link: 'https://en.wikipedia.org/wiki/Severance_(TV_series)',
    },
    {
        name: "Mistborn",
        medium: 'Book',
        category: 'Entertainment',
        subCategory: 'Fantasy',
        link: 'https://en.wikipedia.org/wiki/Mistborn',
    },
    {
        name: "Harry Potter",
        medium: 'Book',
        category: 'Entertainment',
        subCategory: 'Fantasy',
        link: 'https://en.wikipedia.org/wiki/Harry_Potter',
    },
    {
        name: "Guns, Germs, and Steel",
        medium: 'Book',
        category: 'Learning',
        subCategory: 'Anthropology',
        link: 'https://en.wikipedia.org/wiki/Guns,_Germs,_and_Steel',
    },
    {
        name: "The Meditations",
        medium: 'Book',
        category: 'Learning',
        subCategory: 'Philosophy',
        link: 'https://en.wikipedia.org/wiki/Meditations',
    },
    {
        name: "Fear and Trembling",
        medium: 'Book',
        category: 'Learning',
        subCategory: 'Philosophy',
        link: 'https://en.wikipedia.org/wiki/Fear_and_Trembling',
    },
    {
        name: "Avatar: The Last Airbender",
        medium: 'Television',
        category: 'Entertainment',
        subCategory: 'Fantasy',
        link: 'https://en.wikipedia.org/wiki/Avatar:_The_Last_Airbender',
    },
    {
        name: "The Legend of Korra",
        medium: 'Television',
        category: 'Entertainment',
        subCategory: 'Fantasy',
        link: 'https://en.wikipedia.org/wiki/The_Legend_of_Korra',
    },
]