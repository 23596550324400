import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FaPlay, FaCode } from 'react-icons/fa';

import placeholder from '../../../assets/png/placeholder.png';
import './SingleProject.css';

function SingleProject({ id, name, desc, tags, code, demo, image, theme }) {
    const useStyles = makeStyles((t) => ({
        iconBtn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 50,
            height: 50,
            borderRadius: 50,
            border: `2px solid ${theme.fourth}`,
            color: theme.tertiary80,
            transition: 'all 0.2s',
            '&:hover': {
                backgroundColor: theme.primary30,
                color: theme.primary,
                transform: 'scale(1.1)',
                border: `2px solid ${theme.primary30}`,
            },
        },
        icon: {
            fontSize: '1.4rem',
            transition: 'all 0.2s',
            '&:hover': {},
        },
    }));

    const classes = useStyles();

    return (
        <div
            key={id}
            className='singleProject'
            style={{ backgroundColor: theme.secondary, border: `2px solid ${theme.primary}`}}
        >
            <div className='projectContent'>
                <h2
                    id={name.replace(' ', '-').toLowerCase()}
                    style={{ color: theme.primary80 }}
                >
                    {name}
                </h2>
                <img src={image ? image : placeholder} alt={name} />
                <div className='project--showcaseBtn'>
                    <a
                        href={demo}
                        target='_blank'
                        rel='noreferrer'
                        className={classes.iconBtn}
                        aria-labelledby={`${name
                            .replace(' ', '-')
                            .toLowerCase()} ${name
                            .replace(' ', '-')
                            .toLowerCase()}-demo`}
                    >
                        <FaPlay
                            id={`${name
                                .replace(' ', '-')
                                .toLowerCase()}-demo`}
                            className={classes.icon}
                            aria-label='Demo'
                        />
                    </a>
                    <a
                        href={code}
                        target='_blank'
                        rel='noreferrer'
                        className={classes.iconBtn}
                        aria-labelledby={`${name
                            .replace(' ', '-')
                            .toLowerCase()} ${name
                            .replace(' ', '-')
                            .toLowerCase()}-code`}
                    >
                        <FaCode
                            id={`${name
                                .replace(' ', '-')
                                .toLowerCase()}-code`}
                            className={classes.icon}
                            aria-label='Code'
                        />
                    </a>
                </div>
            </div>
            <div className='project--desc'>
                <p
                style={{
                    color: theme.tertiary,
                }}
                >
                    {desc}
                </p>
                <hr></hr>
                <ul className="projects-list">
                    {tags.map((tag, id) => (
                        <li style={{ color: theme.primary }} key={id}>{tag}</li>
                    ))}                
                </ul>
            </div>
        </div>
            
            // {/* <div
            //     className='project--lang'
            //     style={{
            //         background: theme.primary30,
            //         color: theme.tertiary80,
            //     }}
            // >
            //     {tags.map((tag, id) => (
            //         <span key={id}>{tag}</span>
            //     ))}
            // </div> */}
        
    );
}

export default SingleProject;
